import React from 'react';

interface ConfirmationModalProps {
    show: boolean;
    onClose: () => void;
    onConfirm: any;
    title: string;
    message: string;
    prevent: boolean
}

const styles: { [key: string]: React.CSSProperties } = {
    overlay: {
        position: 'fixed',
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
    },
    modal: {
        backgroundColor: 'rgb(14,29,48)',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        padding: '24px',
        width: '384px', // w-96 in tailwind
        maxWidth: '100%',
    },
    title: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '16px',
    },
    message: {
        marginBottom: '24px',
        lineHeight: '1.5',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '16px',
    },
    cancelButton: {
        padding: '8px 16px',
        backgroundColor: '#6b7280', // gray-500
        color: '#fff',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    confirmButton: {
        padding: '8px 16px',
        backgroundColor: '#ef4444', // red-500
        color: '#fff',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    }
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, prevent, onClose, onConfirm, title, message }) => {
    if (!show) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.modal}>
                <h2 style={styles.title}>{title}</h2>
                <p style={styles.message}>{message}</p>
                <div style={styles.buttonContainer}>
                    <button
                        style={styles.cancelButton}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        style={{ 
                            ...styles.confirmButton, 
                            opacity: prevent ? 0.6 : 1,
                            pointerEvents: prevent ? 'none' : 'auto',
                        }}
                        onClick={onConfirm}
                        disabled={prevent}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};


export default ConfirmationModal;
