import { useState } from 'react';
import { Trans } from '@lingui/macro'
import Modal from '../Modal';
import { Text } from 'rebass';
import { ButtonError } from '../Button'
import styled from 'styled-components';

const ConfirmationModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 10px; /* Added margin at the bottom for spacing */
`;
const ConfirmationModalTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
`;
const ConfirmationModalInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
`;

/**
 * ConfirmationModal is a modal that prompts user to type a specific word
 * (by default, "confirm") to confirm an action. If the user types the correct
 * word, the modal will close and the onConfirm callback will be called.
 *
 * @param {boolean} isOpen Whether the modal is open or not
 * @param {function} onDismiss Function to close the modal
 * @param {function} onConfirm Function to call when the user confirms
 * @return {JSX.Element} The modal component
 */
const ConfirmationModal = ({ isOpen, onDismiss, onConfirm }) => {
    const [confirmationInput, setConfirmationInput] = useState('');
    /**
     * Handles the confirmation when user types the correct confirmation word
     * If the confirmation word is correct, it calls the onConfirm callback
     * and closes the modal. If the confirmation word is incorrect, it
     * displays an alert with the message "Incorrect confirmation word".
     */
    const handleConfirm = () => {
        const confirmWord = 'confirm';  // The word to confirm
        if (confirmationInput === confirmWord) {
            onConfirm();
            onDismiss();
        } else {
            alert('Incorrect confirmation word');
        }
    };
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={110}>
            <ConfirmationModalWrapper>
                <ConfirmationModalTitle>Please type the word "confirm" to enable expert mode:</ConfirmationModalTitle>
                <ConfirmationModalInput
                    type="text"
                    value={confirmationInput}
                    onChange={(e) => setConfirmationInput(e.target.value)}
                />
               <br></br>
                <ButtonError onClick={handleConfirm}>
                    <Text fontSize={20} fontWeight={500}>
                        <Trans>Confirm</Trans>
                    </Text>
                </ButtonError>
            </ConfirmationModalWrapper>
        </Modal>
    );
};
export default ConfirmationModal;
