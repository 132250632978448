import { useEffect, useState } from "react";
import Moralis from "moralis";
import { useWeb3React } from "@web3-react/core";
import { useETHBalances } from "state/wallet/hooks"
import "assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import TopBar from "../TopBar";
import CopyHelper from "components/AccountDetails/Copy";
import Loader from "components/Loader";
import axiosInstance from "service/axiosInterceptor";


export const getNetworkName = (chainId) => {
  switch (chainId) {
    case 1:
      return "Ethereum Mainnet";
    case 3:
      return "Ropsten Testnet";
    case 4:
      return "Rinkeby Testnet";
    case 5:
      return "Goerli Testnet";
    case 42:
      return "Kovan Testnet";
    case 56:
      return "Binance Smart Chain";
    case 137:
      return "Polygon Mainnet";
    case 80001:
      return "Mumbai Testnet";
    case 80002:
      return "Amoy Network";
    case 42161:
      return "Arbitrum One";
    case 421611:
      return "Arbitrum Testnet";
    default:
      return "Unknown Network";
  }
};

export default function Header() {
  const { account, chainId } = useWeb3React();
  const [tokens, setTokens] = useState<any[]>([])
  const [tokenBalances, setTokenBalances] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchToken = async () => {
    try {
      const { data } = await axiosInstance.get(`/list-currency`);
      if (data.data.length > 0) {
        setTokens(data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  console.log(tokens,"tokens")

  const fetchTokenBalances = async () => {
    setLoading(true);
    if (!account || !chainId) return;
    try {
      const response = await Moralis.EvmApi.token.getWalletTokenBalances({
        chain: chainId,
        address: account,
      });
      if (response.raw) setTokenBalances(response.raw);
    } catch (e) {
      console.error("Error in fetching token balances:", e);
    } finally {
      setLoading(false);
    }
  };

  console.log(tokenBalances,"tokenBalances")

  useEffect(() => {
    fetchTokenBalances();
    fetchToken();
  }, [account, chainId]);

  const mergedTokenData = tokens.map(token => {
    const balanceInfo = tokenBalances.find(
      balanceToken => balanceToken.token_address.toLowerCase() === token.tokenAddress.toLowerCase()
    );
    return {
      ...token,
      ...balanceInfo,
      balance: balanceInfo 
        ? (parseFloat(balanceInfo.balance) / Math.pow(10, balanceInfo.decimals)).toFixed(4)
        : "0.0000"
    };
  });

  // const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? ""];

  return (
    <div className="content-section">
      <TopBar />
      <div className="menu-heading">
        <div className="balance_heading">
          <div>
            <h2>Your Wallet Address</h2>
            <p>
              {account}
              <CopyHelper toCopy={account || ''} size={'24'} />
            </p>
          </div>
          <div>
            <h2>Your Wallet Network</h2>
            <h3>{getNetworkName(chainId)}</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="wallet-management-detail">
          <div className="wallet-detail">
            <table className="table">
              <thead>
                <tr>
                  <th>Asset</th>
                  <th>Symbol</th>
                  <th>Token Address</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : mergedTokenData.length > 0 ? (
                  mergedTokenData.map((token, index) => (
                    <tr key={index}>
                      <td>{token.name}</td>
                      <td>{token.symbol}</td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                          {token.tokenAddress.slice(0, 4)}...
                          {token.tokenAddress.slice(-4)}
                          &nbsp;&nbsp;
                        </span>
                        <span>
                          <CopyHelper toCopy={token.tokenAddress || ''} size={''} />
                        </span>
                      </td>
                      <td>{token.balance}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center">Data not available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}