import 'assets/index.css'
import TopBar from '../TopBar'
import bgimage  from '../../assets/images/banner-img.png' 

/**
 * A component that renders the header section of the application.
 *
 * The header includes the navigation menu, a welcome message, and a call-to-action
 * to get started.
 */
export default function Header() {

  return (
    <>
    <div className="content-section">
      <TopBar />
      <div className="container">
      <div className='row'>
          <div className="col-lg-12">
              <div className="homebanner">
                <h1>Welcome to <span>Archax Defi Products</span></h1>
                <div className="hz-lines"></div>
                <p>Please select from the menu on the left</p>
                <button className="btns">Get Started</button>
                <div className="img-position">
                      <img src={bgimage} />
                </div>
              </div>
          </div>
       </div>
      </div>
    </div>
   </>
  )
}
