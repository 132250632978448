import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { darken, lighten } from 'polished'
import { useEffect, useMemo } from 'react'
import { Activity } from 'react-feather'
import { t, Trans } from '@lingui/macro'
import styled, { css } from 'styled-components'
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'
import FortmaticIcon from '../../assets/images/fortmaticIcon.png'
import PortisIcon from '../../assets/images/portisIcon.png'
import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'
import { fortmatic, injected, portis, walletconnect, walletlink } from '../../connectors'
import { NetworkContextName } from '../../constants/misc'
import useENSName from '../../hooks/useENSName'
import { useHasSocks } from '../../hooks/useSocksBalance'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import { ButtonSecondary, disConnectButtonSecondary } from '../Button'
import Identicon from '../Identicon'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'
import { useDisconnectWallet } from 'hooks/useDisconnectWallet'
import axios from 'axios'
import { toast } from "react-toastify";

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`
const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0px 40px 0 10px;
  border-radius: 12px;
  font-size:18px !important;
  font-weight:600;
  cursor: pointer;
  user-select: none;
  margin-right:6px;
  :focus {
    outline: none;
  }
`
const Web3StatusDisconnect = styled(disConnectButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 12px 10px;
  border-radius: 12px;
  font-size:18px !important;
  font-weight:600;
  cursor: pointer;
  user-select: none;
  border: 1px solid #0690F3;
  background-color: rgba(255, 255, 255, 0.06);
  color: #6DC2FF;
  justify-content: center;
  // :focus {
  //   outline: none;
  // }
`;

const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`
const Web3StatusConnect = styled(Web3StatusGeneric) <{ faded?: boolean }>`
  background-color: ${({ theme }) => theme.primary4};
  border: none;
  color: ${({ theme }) => theme.primaryText1};
  font-weight: 500;
  :hover,
  :focus {
    border: 1px solid ${({ theme }) => darken(0.05, theme.primary4)};
    color: ${({ theme }) => theme.primaryText1};
  }
  ${({ faded }) =>
    faded &&
    css`
      background-color: ${({ theme }) => theme.primary5};
      border: 1px solid ${({ theme }) => theme.primary5};
      color: ${({ theme }) => theme.primaryText1};
      :hover,
      :focus {
        border: 1px solid ${({ theme }) => darken(0.05, theme.primary4)};
        color: ${({ theme }) => darken(0.05, theme.primaryText1)};
      }
    `}
`
const Web3StatusConnected = styled(Web3StatusGeneric) <{ pending?: boolean }>`
  background-color: ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg1)};
  border: 1px solid ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg2)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  font-weight: 500;
  //flex-direction: row-reverse;
  gap:6px;
  :hover,
  :focus {
    background-color: ${({ pending, theme }) => (pending ? darken(0.05, theme.primary1) : lighten(0.05, theme.bg1))};
    :focus {
      border: 1px solid ${({ pending, theme }) => (pending ? darken(0.1, theme.primary1) : darken(0.1, theme.bg2))};
    }
  }
`
const Text = styled.p`
  // flex: 1 1 auto;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  margin: 0;
  padding:13px 6px
  font-size:18px;
  color:#6DC2FF;
  font-weight:600;
  color:#6DC2FF;
  border-radius: 10px;
  //background: rgba(11, 134, 222, 0.30);
  // font-size: 1rem;
  width: fit-content;
  // font-weight: 500;
`
const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`
// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}
/**
 * Returns a span with an emoji representing a sock.
 * The emoji is used to represent a "socks" token in the UI.
 * The span has an aria-label that is translated to "has socks emoji"
 * and has a marginTop and marginBottom of -4px to align it with the rest of the text.
 */
function Sock() {
  return (
    <span role="img" aria-label={t`has socks emoji`} style={{ marginTop: -4, marginBottom: -4 }}>
      🧦
    </span>
  )
}
// eslint-disable-next-line react/prop-types
function StatusIcon({ connector }: { connector: AbstractConnector }) {
  if (connector === injected) {
    return <Identicon />
  } else if (connector === walletconnect) {
    return (
      <IconWrapper size={16}>
        <img src={WalletConnectIcon} alt={'WalletConnect'} />
      </IconWrapper>
    )
  } else if (connector === walletlink) {
    return (
      <IconWrapper size={16}>
        <img src={CoinbaseWalletIcon} alt={'CoinbaseWallet'} />
      </IconWrapper>
    )
  } else if (connector === fortmatic) {
    return (
      <IconWrapper size={16}>
        <img src={FortmaticIcon} alt={'Fortmatic'} />
      </IconWrapper>
    )
  } else if (connector === portis) {
    return (
      <IconWrapper size={16}>
        <img src={PortisIcon} alt={'Portis'} />
      </IconWrapper>
    )
  }
  return null
}
/**
 * Web3StatusInner component
 * @prop {string | undefined} account The currently connected account, if any
 * @prop {AbstractConnector | undefined} connector The connector currently being used to connect to the wallet
 * @prop {Error | undefined} error An error that occurred when connecting to the wallet
 * @prop {boolean} hasPendingTransactions Whether there are any pending transactions
 * @prop {boolean} hasSocks Whether the user has any socks
 * @prop {() => void} toggleWalletModal A function to toggle the wallet modal on and off
 * @returns {JSX.Element} A Web3StatusConnected component if the user is connected, a Web3StatusError component if there is an error, or a
 * Web3StatusConnect component if the user is not connected
 */
function Web3StatusInner() {
  const { account, connector, error, } = useWeb3React()
  const { ENSName } = useENSName(account ?? undefined)
  const allTransactions = useAllTransactions()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const hasPendingTransactions = !!pending.length
  const hasSocks = useHasSocks()
  const toggleWalletModal = useWalletModalToggle()
  const disconnect = useDisconnectWallet()
  const DISCONNECTED_KEY = 'WALLET_DISCONNECTED'

  const polygonMumbaiTestnetParams = {
    chainId: '0x13882', // 80001 in hexadecimal
    chainName: 'Polygon amoy Testnet',
    nativeCurrency: {
      name: 'POL',
      symbol: 'POL',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-amoy.polygon.technology'],
    blockExplorerUrls: ['https://amoy.polygonscan.com'],
  };


  const switchToPolygonNetwork = async () => {
    const { ethereum } = window
    if (!ethereum) {
      console.error("Ethereum object doesn't exist!")
      return
    }

    try {
      // Try to switch to the Polygon network
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: polygonMumbaiTestnetParams.chainId }],
      })
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask
      if (switchError.code === 4902) {
        try {
          // Add Polygon network to MetaMask
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [polygonMumbaiTestnetParams],
          })
        } catch (addError) {
          console.error('Failed to add Polygon network', addError)
        }
      } else {
        console.error('Failed to switch network', switchError)
      }
    }
  }

  const userLogin = async (walletAddress: string) => {
    try {
      const { data } = await axios.post('https://archaxdev.seaswap.co/api/v1/user/login', { walletAddress })
      if (data) {
        console.log('API response:', data)
        localStorage.setItem(DISCONNECTED_KEY, 'false');
        localStorage.setItem('sessionToken', JSON.stringify(data.data.session.session_token))
        localStorage.setItem('walletAddress', JSON.stringify(data.data.user.walletAddress))
      }
    } catch (error) {
      console.error('Error IN LOGIN API:', error.status)

      if (error.status === 400) {
        disconnect()
        localStorage.setItem(DISCONNECTED_KEY, 'true')
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('walletAddress');
        toast.dismiss()
        toast.error("You are not whitelisted", { autoClose: 3000 })
      }
    }
  }


  useEffect(() => {
    const storedAddress = localStorage.getItem('walletAddress');

    if (account) {
      localStorage.setItem(DISCONNECTED_KEY, 'false');
      // Check if the account address is different from the stored address
      if (storedAddress && storedAddress !== JSON.stringify(account)) {
        userLogin(account)
        // disconnect(); // Disconnect if addresses do not match
        // localStorage.setItem(DISCONNECTED_KEY, 'true');
        // localStorage.removeItem('sessionToken');
        // localStorage.removeItem('walletAddress');
        // toast.error("You are not whitelisted", { autoClose: 3000 })
      } else {
        const token = localStorage.getItem('sessionToken');
        if (!token) {
          userLogin(account); // Log in if no session token
        }
      }
    }
  }, [account]);

  if (account) {
    return (
      <>
        <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>
          {hasPendingTransactions ? (
            <RowBetween>
              <Text>
                <Trans>{pending?.length} Pending</Trans>
              </Text>{' '}
              <Loader stroke="white" />
            </RowBetween>
          ) : (
            <>
              {hasSocks ? <Sock /> : null}
            </>
          )}
          {!hasPendingTransactions && connector && <StatusIcon connector={connector} />}
          <Text>{ENSName || shortenAddress(account)}</Text>

        </Web3StatusConnected>
        <Web3StatusDisconnect onClick={disconnect} >
          <Trans>Disconnect</Trans>
        </Web3StatusDisconnect>
      </>
    )
  } else if (error) {
    console.log(error, "err")
    switchToPolygonNetwork()

    return (
      <>
        <Web3StatusError onClick={toggleWalletModal}>
          <NetworkIcon />
          <Text>{error instanceof UnsupportedChainIdError ? <Trans>Switch Network</Trans> : <Trans>Error</Trans>}</Text>
        </Web3StatusError>
      </>
    )
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={toggleWalletModal} faded={!account} className='jsgdfhjgdsj'>
        <Trans>Connect to a wallet</Trans>
      </Web3StatusConnect>
    )
  }
}
/**
 * Web3Status component
 * @prop {boolean} active Is the web3 context active
 * @prop {string | undefined} account The currently connected account, if any
 * @prop {ENSName | undefined} ENSName The ENS name associated with the connected account, if applicable
 * @prop {string[]} pendingTransactions An array of transaction hashes that are pending
 * @prop {string[]} confirmedTransactions An array of transaction hashes that have been confirmed
 * @returns {JSX.Element} A Web3StatusInner component and a WalletModal component if the user is connected, or null if the user is not connected
 */
export default function Web3Status() {
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)
  const { ENSName } = useENSName(account ?? undefined)
  const allTransactions = useAllTransactions()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)
  if (!contextNetwork.active && !active) {
    return null
  }
  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}