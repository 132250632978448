import 'assets/index.css';
import TopBar from '../TopBar';
import { useEffect, useState } from 'react';
import Moralis from 'moralis';
import { useWeb3React } from '@web3-react/core';
import { listToken, listWallet } from 'service/walletService';
import Loader from 'components/Loader';


export const getNetworkSymbol = (chainId) => {
  switch (chainId) {
    case 1:
      return "ETH"; // Ethereum Mainnet
    case 3:
      return "ETH"; // Ropsten Testnet
    case 4:
      return "ETH"; // Rinkeby Testnet
    case 5:
      return "ETH"; // Goerli Testnet
    case 42:
      return "ETH"; // Kovan Testnet
    case 56:
      return "BNB"; // Binance Smart Chain
    case 137:
      return "POL"; // Polygon Mainnet
    case 80001:
      return "POL"; // Mumbai Testnet
    case 80002:
      return "POL"; // Amoy Network
    case 42161:
      return "ETH"; // Arbitrum One
    case 421611:
      return "ETH"; // Arbitrum Testnet
    default:
      return "UNKNOWN"; // Unknown Network
  }
};


export default function Header() {
  const { account, chainId } = useWeb3React();
  const [tokenTransactions, setTokenTransactions] = useState<any[]>([]);
  const [tokens,setTokens]=useState<any[]>([]);
  const [walletTransactions, setWalletTransactions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [resultsPerPage] = useState(10);
  const [labelMap, setLabelMap] = useState<{ [key: string]: string }>({});
  // const [viewType, setViewType] = useState<'wallet' | 'token'>('token'); // 'wallet' or 'token'

  const fetchToken = async () => {
    try {
      const { data } = await listToken();
      if (data) {
        setTokens(data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchWallet = async () => {
    try {
      const { data } = await listWallet(account);
      if (data) {
        const newLabelMap = data.reduce((acc, { address, label }) => {
          acc[address.toLowerCase()] = label;
          return acc;
        }, {});
        setLabelMap(newLabelMap);
        return newLabelMap;
      }
    } catch (error) {
      console.log(error);
    }
    return {};
  }

  console.log(labelMap,"labelMap")

  const updateTransactionsWithLabels = (transactions, labels) => {
    return transactions.map((tx) => ({
      ...tx,
      from_address_label: tx.from_address ? labels[tx.from_address.toLowerCase()] || null : null,
      to_address_label: tx.to_address ? labels[tx.to_address.toLowerCase()] || null : null,
    }));
  }

  const fetchTransactionHistory = async (labels) => {
    if (!account || !chainId) return;

    try {
      setLoading(true);

      // Fetch token transactions
      const tokenResponse = await Moralis.EvmApi.token.getWalletTokenTransfers({
        address: account,
        chain: chainId,
      });

      const updatedTokenTransactions = updateTransactionsWithLabels(tokenResponse.toJSON().result, labels);
      setTokenTransactions(updatedTokenTransactions);

      // Fetch wallet transactions
      // const walletResponse = await Moralis.EvmApi.transaction.getWalletTransactions({
      //   chain: chainId,
      //   address: account,
      // });

      // const updatedWalletTransactions = updateTransactionsWithLabels(walletResponse.raw.result, labels);
      // setWalletTransactions(updatedWalletTransactions);
    } catch (e: any) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await fetchToken()
      const labels = await fetchWallet();
      await fetchTransactionHistory(labels);
    };

    if (account && chainId) {
      loadData();
    }
  }, [account, chainId, resultsPerPage]);

  // const handleTransactionTypeSwitch = (type: 'wallet' | 'token') => {
  //   setViewType(type);
  //   if (type === 'wallet') {
  //     setCurrentPage(1);
  //   } else {
  //     setCurrentPage(1);
  //   }
  // };


  const currentTransactions = tokenTransactions.filter((tx) =>
    tokens.some((token) => token.tokenAddress.toLowerCase() === tx.address.toLowerCase())
  );

  console.log(tokenTransactions,"tokenTransactions")
  console.log(tokens,"tokens")

  // Slice the transactions for the current page
  const paginatedTransactions = currentTransactions.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage
  );

  // Calculate total pages based on filtered transactions
  useEffect(() => {
    const totalResults = currentTransactions.length;
    setTotalPages(Math.ceil(totalResults / resultsPerPage));
  }, [currentTransactions, resultsPerPage]);

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const shortenContent = (value: string) => value?.length > 11 ? value.slice(0, 4) + '...' + value.slice(-4) : value;

  return (
    <>
      <div className="content-section">
        <TopBar />
        <div className="top-110">
          <div className="container">
            <div className="wallet-management-detail">
              <div className="wallet-detail">
                {/* <div className="btn-group">
                  <button onClick={() => handleTransactionTypeSwitch('token')} className={`addnew ${viewType === 'token' ? 'active' : ''}`}>
                    Token Transactions
                  </button>
                  <button onClick={() => handleTransactionTypeSwitch('wallet')} className={`addnew ${viewType === 'wallet' ? 'active' : ''}`}>
                    Wallet Transactions
                  </button>
                </div> */}

                <table className='table'>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>TX Hash</th>
                      <th>From Label</th>
                      <th>From Address</th>
                      <th>To Label</th>
                      <th>To Address</th>
                      <th>Total Amount</th>

                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr className="text-center">
                        <td colSpan={7}><Loader /></td>
                      </tr>
                    ) : paginatedTransactions.length > 0 ? (
                      paginatedTransactions.map((tx: any, index: number) => {

                        const {
                          hash,
                          transaction_hash,
                          from_address,
                          to_address,
                          value,
                          token_symbol,
                          value_decimal,
                          block_timestamp,
                          from_address_label,
                          to_address_label,
                        } = tx;

                        const decimalValue = parseFloat(value_decimal).toFixed(4);
                        // const displayValue = viewType === 'wallet'
                        //   ? `${(parseFloat(value) / Math.pow(10, 18)).toFixed(6)} ${getNetworkSymbol(chainId)}`
                        //   : `${decimalValue} ${token_symbol}`; // Use decimalValue for else condition

                        const displayValue = `${decimalValue} ${token_symbol}`; // Use decimalValue for else condition
                        return (
                          <tr key={index}>
                            <td>{new Date(block_timestamp).toLocaleDateString()}</td>
                            <td>
                              <a href={`https://www.oklink.com/amoy/tx/${hash || transaction_hash}`} target="_blank" rel="noopener noreferrer">
                                {hash ? shortenContent(hash) : shortenContent(transaction_hash)}
                              </a>
                            </td>
                            <td>{from_address_label || '-'}</td>
                            <td>{shortenContent(from_address) || '-'}</td>
                            <td>{to_address_label || '-'}</td>
                            <td>{shortenContent(to_address) || '-'}</td>
                            <td>{displayValue}</td>

                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7} className='text-center'>No Transactions Available</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {currentTransactions.length > 0 && (
                  <div className='pagination-wrap'>
                    <button className='prevs-btn'
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1 || loading}
                    >
                      Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button className='next-btn'
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages || loading}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
