import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://archaxdev.seaswap.co/api/v1/user",
});

// Create a response interceptor to handle session expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.setItem('WALLET_DISCONNECTED', 'true')
      localStorage.removeItem('sessionToken')
    }
    return Promise.reject(error);
  }
);

// Create a request interceptor to add the session token to requests
axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("sessionToken");
    if (token) {
      const sessionToken = JSON.parse(token);
      if (config.url && !config.url.includes("/login")) {
        if (sessionToken) {
          config.headers["Authorization"] = `Bearer ${sessionToken}`;
        }
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export default axiosInstance;
