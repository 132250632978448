import { Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
import Web3ReactManager from '../components/Web3ReactManager'
import ErrorBoundary from '../components/ErrorBoundary'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
import Earn from './Earn'
import Manage from './Earn/Manage'
import MigrateV2 from './MigrateV2'
import MigrateV2Pair from './MigrateV2/MigrateV2Pair'
import Pool from './Pool'
import PoolV2 from './Pool/v2'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import Swap from './Swap'
import NewHome from '../components/NewHome'
import WalletManagement from '../components/WalletManagement'
import Balance from '../components/Balanace'
import WalletTransaction from '../components/WalletTransaction'
import { OpenClaimAddressModalAndRedirectToSwap, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
import { PositionPage } from './Pool/PositionPage'
import AddLiquidity from './AddLiquidity'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader';
import { ToastContainer } from 'react-toastify';
import Welcome from './Welcome'
import ConnectWallet from './ConnectWallet'
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const AppWrapper = styled.div`
  // display: flex;
  // flex-flow: column;
  // align-items: flex-start;
  // height: 100vh;
`
const BodyWrapper = styled.div`
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // padding-top: 150px;
  // align-items: center;
  // flex: 1;
  // z-index: 1;
  // // justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    // padding: 16px;
    // padding-top: 6rem;
  `};
`
const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  // width: 100%;
  // justify-content: space-between;
  // position: fixed;
  // top: 0;
  // z-index: 2;
`
const Marginer = styled.div`
  margin-top: 5rem;
`

/**
 * Always rendered modals. Used for displaying important warnings and information.
 */
function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

/**
 * Top level component for the app, renders everything except the modal.
 *
 * Redirects to /welcome if no account or chainId is present.
 *
 * @returns {ReactElement} The rendered component.
 */
export default function App() {
  const { account, chainId } = useWeb3React();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      setLoading(false);
      return;
    }
    // If no account or chainId, redirect to /welcome
    if (!account || !chainId) {
      if (location.pathname !== '/welcome') {
        history.replace('/welcome');
      }
    }
  }, [account, chainId, history, location.pathname]);
  return (
    <ErrorBoundary>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <AppWrapper>
        <ToastContainer />
        <BodyWrapper>
          <div className='huge'>
            <Header />
            <Web3ReactManager>
              <Switch>
                <Route exact strict path="/newhome" component={NewHome} />
                <Route exact strict path="/balance" component={Balance} />
                <Route exact strict path="/walletmanagement" component={WalletManagement} />
                <Route exact strict path="/wallettransaction" component={WalletTransaction} />
                <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />
                <Route exact strict path="/uni" component={Earn} />
                <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/swap" component={Swap} />
                <Route exact strict path="/welcome" component={Welcome} />
                <Route exact strict path="/connect-wallet" component={ConnectWallet} />
                <Route exact strict path="/pool/v2/find" component={PoolFinder} />
                <Route exact strict path="/pool/v2" component={Pool} />
                <Route exact strict path="/pool" component={PoolV2} />
                <Route exact strict path="/pool/:tokenId" component={PositionPage} />
                <Route exact strict path="/add/v2/:currencyIdA?/:currencyIdB?" component={RedirectDuplicateTokenIdsV2} />
                <Route exact strict path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?" component={RedirectDuplicateTokenIds} />
                <Route exact strict path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?" component={AddLiquidity} />
                <Route exact strict path="/remove/v2/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/remove/:tokenId" component={RemoveLiquidityV3} />
                <Route exact strict path="/migrate/v2" component={MigrateV2} />
                <Route exact strict path="/migrate/v2/:address" component={MigrateV2Pair} />
                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Web3ReactManager>
            <Marginer />
          </div>
        </BodyWrapper>
      </AppWrapper>
    </ErrorBoundary>
  );
}