import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// const bounce = keyframes`
//   0%, 100% { transform: translateY(-25%); animation-timing-function: cubic-bezier(0.8, 0, 1, 1); }
//   50% { transform: translateY(0); animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
// `;

const pulse = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: .5; }
`;

const LoaderContainer = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 55, 0.75);
  z-index: 50;
`;

const LoaderContent = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  transform: translateY(-25%);
  transition: all 0.3s;
  
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Spinner = styled.div`
  width: 4rem;
  height: 4rem;
  border: 4px solid #3B82F6;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const LoadingText = styled.h2`
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1F2937;
`;

const LoadingSubtext = styled.p`
  margin-top: 0.5rem;
  color: #4B5563;
`;

const DotContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
`;

interface DotProps {
    delay?: string; // optional delay prop
}

// Styled component for Dot
const Dot = styled.div<DotProps>`
    width: 0.75rem;
    height: 0.75rem;
    background-color: #3B82F6;
    border-radius: 50%;
    animation: ${pulse} 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation-delay: ${({ delay }) => delay || '0ms'}; // Use the delay prop
  `;

const TransactionLoader = () => {
    return (
        <LoaderContainer>
            <LoaderContent>
                <FlexColumn>
                    <Spinner />
                    <LoadingText>Transaction in Progress...</LoadingText>
                    <LoadingSubtext>We're almost there! Hang tight while we complete your transaction.</LoadingSubtext>
                    <DotContainer>
                        <Dot />
                        <Dot delay="75ms" />
                        <Dot delay="150ms" />
                    </DotContainer>
                </FlexColumn>
            </LoaderContent>
        </LoaderContainer>
    );
};

export default TransactionLoader;