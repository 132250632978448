import { useState } from 'react'
import { Slash } from 'react-feather'
import { ImageProps } from 'rebass'
import useTheme from '../../hooks/useTheme'

const BAD_SRCS: { [tokenAddress: string]: true } = {}

export interface LogoProps extends Pick<ImageProps, 'style' | 'alt' | 'className'> {
  srcs: string[]
}

/**
 * A Logo component that renders an image from a list of srcs. If none of the srcs load, it renders a Slash icon.
 *
 * @param srcs a list of srcs to try to load
 * @param alt text to display if the image doesn't load
 * @param style styling for the image
 * @param rest props to pass through to the img element
 */
export default function Logo({ srcs, alt, style, ...rest }: LogoProps) {
  const [, refresh] = useState<number>(0)
  const theme = useTheme()
  const src: string | undefined = srcs.find((src) => !BAD_SRCS[src])
  if (src) {
    return (
      <img
        {...rest}
        alt={alt}
        src={src}
        style={style}
        onError={() => {
          if (src) BAD_SRCS[src] = true
          refresh((i) => i + 1)
        }}
      />
    )
  }
  return <Slash {...rest} style={{ ...style, color: theme.bg4 }} />
}
