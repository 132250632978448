import CopyHelper from 'components/AccountDetails/Copy';
import Loader from 'components/Loader';
import { useActiveWeb3React } from 'hooks/web3';
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { deleteWallet, listWallet } from 'service/walletService';
import { formatDateToIST } from 'utils/formatDate';
import ConfirmationModal from 'components/shared/ConfirmationModal';
import axiosInstance from 'service/axiosInterceptor';


const ListWallet = ({ isLoading, setWalletAddress, handleTransactionOpenModal, fetchWallet, walletAddress, setSelectedAddress }) => {
    const { account } = useActiveWeb3React();
    const [showModal, setShowModal] = useState(false);
    const [walletToDelete, setWalletToDelete] = useState<any>(null);
    const [preventDelete, setPreventDelete] = useState(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null)
    const [editingAddress, setEditingAddress] = useState<string>('')
    const [labelValue, setLabelValue] = useState<string>('')
    const inputRef = useRef<HTMLInputElement>(null)
    const labelValueRef = useRef<string>('')

    const handleDeleteConfirmation = (address: any) => {
        setWalletToDelete(address);
        setShowModal(true);
    };

    const confirmDelete = async () => {
        setPreventDelete(true);
        try {
            if (walletToDelete) {
                const { data } = await deleteWallet(account, walletToDelete);
                if (data) {
                    fetchWallet();
                    toast.dismiss();
                    toast.success('Wallet Deleted Successfully', { autoClose: 3000 });
                    setShowModal(false);
                    setWalletToDelete(null);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setPreventDelete(false);
        }
    };

    const handleTransaction = (item) => {
        handleTransactionOpenModal()
        setSelectedAddress(item)
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
            if (editingIndex !== null && labelValueRef.current.trim() !== "") {
                saveLabel()
            }
        }
    }

    useEffect(() => {
        if (editingIndex !== null) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [editingIndex])

    useEffect(() => {
        labelValueRef.current = labelValue;
    }, [labelValue]);

    const handleEditClick = (index: number, currentLabel: string, address: string) => {
        setEditingIndex(index)
        setLabelValue(currentLabel)
        setEditingAddress(address)
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 0);
    }

    const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLabelValue(e.target.value)
    }

    const saveLabel = async () => {
        setEditingIndex(null)
        const currentLabelValue = labelValueRef.current;
        try {
            const { data } = await axiosInstance.patch(`/update-wallet-label/${account}`, {
                walletAddress: editingAddress,
                label: currentLabelValue
            });
            if (data) {
                setLabelValue('')
                await fetchWalletWithoutLoading()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchWalletWithoutLoading = async () => {
        try {
            const { data } = await listWallet(account);
            if(data) setWalletAddress(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchWallet()
    }, [account])


    return (
        <>
            {
                isLoading ? (
                    <tr>
                        <td colSpan={4} className="text-center">
                            <Loader />
                        </td>
                    </tr>
                ) : (
                    walletAddress.length > 0 ? (
                        walletAddress.map((item: any, index: number) => (
                            <tr key={index}>
                                <td>{editingIndex === index ? (
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        value={labelValue}
                                        onChange={handleLabelChange}
                                        placeholder="Enter Label"
                                        style={{
                                            minWidth: '110px', boxSizing: 'border-box', padding: 0,color: '#fff'
                                        }}

                                    />
                                ) : (
                                    <>
                                        {item.label}
                                        <button
                                            className="labeledit-btn"
                                            onClick={() => handleEditClick(index, item.label, item.address)}
                                        >
                                            <svg
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clipPath="url(#clip0_1949_10145)">
                                                    <path
                                                        d="M7.03685 1.94272C7.40945 1.53904 7.59575 1.3372 7.7937 1.21946C8.27135 0.93538 8.85955 0.926545 9.3452 1.19616C9.54645 1.3079 9.73845 1.50406 10.1225 1.89638C10.5066 2.2887 10.6986 2.48486 10.808 2.69046C11.0719 3.18656 11.0633 3.7874 10.7852 4.27535C10.6699 4.47758 10.4723 4.66789 10.0772 5.0485L5.3753 9.57715C4.62644 10.2985 4.252 10.6591 3.78403 10.8419C3.31606 11.0247 2.8016 11.0112 1.77268 10.9843L1.63269 10.9806C1.31946 10.9724 1.16284 10.9683 1.0718 10.865C0.980751 10.7617 0.993181 10.6022 1.01804 10.2831L1.03154 10.1098C1.10151 9.21175 1.13649 8.76275 1.31186 8.3591C1.48722 7.95545 1.78972 7.62775 2.39472 6.97225L7.03685 1.94272Z"
                                                        stroke="currentColor"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M6.5 2L10 5.5"
                                                        stroke="currentColor"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M7 11H11"
                                                        stroke="currentColor"
                                                        strokeWidth="0.75"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1949_10145">
                                                        <rect width="12" height="12" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </button>
                                    </>
                                )}</td>
                                <td><div className='address-wrap'><p>{item.address}</p><CopyHelper toCopy={item.address} size={'24'} /></div></td>
                                <td>{formatDateToIST(item.createdAt)}</td>
                                <td>
                                    <button className="transation-btn" onClick={() => handleTransaction(item)}>
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.0003 9.44959H5.34908C3.99205 9.44959 3.31353 9.4496 3.14468 9.03315C2.97582 8.6167 3.4556 8.12658 4.41517 7.14634L7.44353 4.05273" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M3.11133 16.5508H19.7625C21.1196 16.5508 21.7981 16.5508 21.967 16.9673C22.1358 17.3837 21.656 17.8738 20.6965 18.854L17.6681 21.9476" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                    </button>
                                    <span onClick={() => handleDeleteConfirmation(item.address)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M16.25 4.5835L15.7336 12.9377C15.6016 15.0722 15.5357 16.1394 15.0007 16.9067C14.7361 17.2861 14.3956 17.6062 14.0006 17.8468C13.2017 18.3335 12.1325 18.3335 9.99392 18.3335C7.8526 18.3335 6.78192 18.3335 5.98254 17.8459C5.58733 17.6049 5.24667 17.2842 4.98223 16.9042C4.4474 16.1357 4.38287 15.0669 4.25384 12.9295L3.75 4.5835"
                                                stroke="#FF6D6D"
                                                strokeWidth="1.25"
                                                strokeLinecap="round"
                                            />
                                            <path
                                                d="M2.5 4.58317H17.5M13.3797 4.58317L12.8109 3.40961C12.433 2.63005 12.244 2.24027 11.9181 1.99718C11.8458 1.94325 11.7693 1.89529 11.6892 1.85375C11.3283 1.6665 10.8951 1.6665 10.0287 1.6665C9.14067 1.6665 8.69667 1.6665 8.32973 1.8616C8.24842 1.90485 8.17082 1.95475 8.09774 2.01081C7.76803 2.26375 7.58386 2.6678 7.21551 3.47589L6.71077 4.58317"
                                                stroke="#FF6D6D"
                                                strokeWidth="1.25"
                                                strokeLinecap="round"
                                            />
                                            <path
                                                d="M7.9165 13.75V8.75"
                                                stroke="#FF6D6D"
                                                strokeWidth="1.25"
                                                strokeLinecap="round"
                                            />
                                            <path
                                                d="M12.084 13.75V8.75"
                                                stroke="#FF6D6D"
                                                strokeWidth="1.25"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </span>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={4} className="text-center">No Data Available</td>
                        </tr>
                    ))
            }

            <ConfirmationModal
                show={showModal}
                prevent={preventDelete}
                onClose={() => setShowModal(false)} // Close modal on cancel
                onConfirm={confirmDelete} // Confirm delete action
                title="Confirm Deletion"
                message="Are you sure you want to delete this wallet address?"
            />

        </>
    )
}

export default ListWallet