import Tooltip from 'components/Tooltip'
import { useState } from 'react'
import styled from 'styled-components/macro'

const TextWrapper = styled.span<{ margin: boolean; link?: boolean; fontSize?: string; adjustSize?: boolean }>`
  margin-left: ${({ margin }) => margin && '4px'};
  color: ${({ theme, link }) => (link ? theme.blue1 : theme.text1)};
  font-size: ${({ fontSize }) => fontSize ?? 'inherit'};
  @media screen and (max-width: 600px) {
    font-size: ${({ adjustSize }) => adjustSize && '12px'};
  }
`

/**
 * Displays a given text, with a tooltip displaying the full text if it is longer than the given maxCharacters.
 * If the text is not longer than maxCharacters, it is simply displayed inline.
 * @param text The text to display.
 * @param maxCharacters The maximum number of characters to display inline.
 * @param margin Whether to add a margin-left to the displayed text.
 * @param adjustSize Whether to reduce the font size of the displayed text when the screen is smaller than 600px.
 * @param fontSize The font size of the displayed text.
 * @param link Whether the displayed text should be styled as a link.
 */
const HoverInlineText = ({
  text,
  maxCharacters = 20,
  margin = false,
  adjustSize = false,
  fontSize,
  link,
  ...rest
}: {
  text?: string
  maxCharacters?: number
  margin?: boolean
  adjustSize?: boolean
  fontSize?: string
  link?: boolean
}) => {
  const [showHover, setShowHover] = useState(false)
  if (!text) {
    return <span />
  }
  if (text.length > maxCharacters) {
    return (
      <Tooltip text={text} show={showHover}>
        <TextWrapper
          onMouseEnter={() => setShowHover(true)}
          onMouseLeave={() => setShowHover(false)}
          margin={margin}
          adjustSize={adjustSize}
          link={link}
          fontSize={fontSize}
          {...rest}
        >
          {' ' + text.slice(0, maxCharacters - 1) + '...'}
        </TextWrapper>
      </Tooltip>
    )
  }
  return (
    <TextWrapper margin={margin} adjustSize={adjustSize} link={link} fontSize={fontSize} {...rest}>
      {text}
    </TextWrapper>
  )
}
export default HoverInlineText
