import useScrollPosition from '@react-hook/window-scroll'
import { useEffect, useState } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import styled from 'styled-components/macro'
import Logo from '../../assets/png/logo_white.png'
import LogoDark from '../../assets/png/logo_white.png'
import { SupportedChainId } from '../../constants/chains'
import { useActiveWeb3React } from '../../hooks/web3'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { ExternalLink } from '../../theme'
import { YellowCard } from '../Card'
import Row, { RowFixed } from '../Row'
import { useToggleSelfClaimModal, useShowClaimPopup } from '../../state/application/hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import logoemblam from '../../assets/images/mini-logo.png';
import { useHistory,useLocation } from 'react-router-dom';

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 21;
  position: relative;

  /* Background slide effect on scroll. */
  background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg3} 50% )}}`}
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position .1s, box-shadow .1s;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns: auto 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    // flex-direction: row;
    // justify-content: space-between;
    // justify-self: center;
    // width: 100%;
    // max-width: 960px;
    // padding: 1rem;
    // position: fixed;
    // bottom: 0px;
    // left: 0px;
    // width: 100%;
    // z-index: 99;
    // height: 72px;
    // border-radius: 12px 12px 0 0;
    // background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.bg0};
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(50px);
  width: fit-content;
  padding: 4px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: flex-end;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg2)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  img {
    width: 7em;
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
    background: ${({ theme }) => theme.bg7};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
}) <{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: none;
`}
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg2};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }
  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const NETWORK_LABELS: { [chainId in SupportedChainId | number]: string } = {
  [SupportedChainId.MAINNET]: 'Mainnet',
  [SupportedChainId.RINKEBY]: 'Rinkeby',
  [SupportedChainId.ROPSTEN]: 'Ropsten',
  [SupportedChainId.GOERLI]: 'Görli',
  [SupportedChainId.KOVAN]: 'Kovan',
  [SupportedChainId.MUMBAI]: 'Mumbai',
  [SupportedChainId.AMOY]: 'AMOY',
  [SupportedChainId.ARBITRUM_KOVAN]: 'kArbitrum',
  [SupportedChainId.ARBITRUM_ONE]: 'Arbitrum One',
}

export default function Header() {
  const history = useHistory();
  const location = useLocation();
  // const pathWithoutSlash = location.pathname.slice(1);
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const showClaimPopup = useShowClaimPopup()

  const scrollY = useScrollPosition()

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const handleClick = (path) => {
    if (!account) return;
    setActiveLink(path);
    history.push(path);
    // setIsOpen(false);
  };

  return (
    <>
      <div className='main-wrap'>
        <div className='sidenav'>
          <HeaderRow className='mb-50'>
            <Title href=".">
              <UniIcon>
                <img src={darkMode ? LogoDark : Logo} alt="logo" className='web' />
                <img src={logoemblam} alt="" className='tab' />
              </UniIcon>
            </Title>
          </HeaderRow>
          <ul className='sidebar-menu'>
            <li className={`${activeLink === "/swap" ? "active-link" : ""}`} onClick={() => handleClick("/swap")}><a>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M17.0833 4.5835H7.91667C4.82227 4.5835 2.5 6.82102 2.5 10.0002" stroke="#6DC2FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M2.91669 15.4167H12.0834C15.1778 15.4167 17.5 13.1792 17.5 10" stroke="#6DC2FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15.4167 2.5C15.4167 2.5 17.5 4.03435 17.5 4.58335C17.5 5.13235 15.4167 6.66667 15.4167 6.66667" stroke="#6DC2FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M4.58332 13.3335C4.58332 13.3335 2.50001 14.8678 2.5 15.4168C2.49999 15.9658 4.58333 17.5002 4.58333 17.5002" stroke="#6DC2FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                </svg></span><p>DEX</p>
            </a>
            </li>
            <li><a onClick={handleToggle}>
              <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M12.5 12.5C12.5 13.1903 13.0597 13.75 13.75 13.75C14.4403 13.75 15 13.1903 15 12.5C15 11.8097 14.4403 11.25 13.75 11.25C13.0597 11.25 12.5 11.8097 12.5 12.5Z" stroke="#6DC2FF" />
                <path d="M12.5032 6.50234C7.98016 6.18892 4.25392 5.51778 2.5 5V12.5512C2.5 14.2132 2.5 15.0442 3.01632 15.722C3.53263 16.3997 4.24103 16.5911 5.65781 16.9739C7.94686 17.5924 10.3529 17.9608 12.5088 18.1714C14.7432 18.3898 15.8604 18.4989 16.6802 17.7498C17.5 17.0006 17.5 15.7972 17.5 13.3902V11.7122C17.5 9.37478 17.5 8.20608 16.8274 7.48073C16.1549 6.75538 14.9377 6.67103 12.5032 6.50234Z" stroke="#6DC2FF" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.6882 6.6665C15.0029 5.48044 15.2877 3.32335 14.4392 2.25227C13.9013 1.57339 13.1019 1.63862 12.3182 1.70755C8.19826 2.06989 5.28787 2.80593 3.66084 3.30598C2.96123 3.521 2.5 4.20419 2.5 4.96687" stroke="#6DC2FF" stroke-linejoin="round" />
              </svg></span><p >Collateral Transfer</p>
            </a>
            {isOpen && (
              <ul>
                <li className={`${activeLink === "/balance" ? "sub-active-link" : ""}`} onClick={() => handleClick("/balance")}><a><span>
                  <svg width="20" height="20" viewBox="0 0 1024 1024" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M512 833.6c-13.6 0-24-10.4-24-24v-67.2H384c-13.6 0-24-10.4-24-24s10.4-24 24-24h104v-60.8H384c-13.6 0-24-10.4-24-24s10.4-24 24-24h111.2L384 474.4c-4.8-4.8-7.2-10.4-7.2-16.8s2.4-12.8 7.2-16.8c4.8-4.8 10.4-7.2 16.8-7.2s12.8 2.4 16.8 7.2L512 534.4 605.6 440c4.8-4.8 10.4-7.2 16.8-7.2s12.8 2.4 16.8 7.2c9.6 9.6 9.6 24.8 0 33.6l-110.4 112H640c13.6 0 24 10.4 24 24s-10.4 24-24 24H536v60.8h104c13.6 0 24 10.4 24 24s-10.4 24-24 24H536v67.2c0 12.8-10.4 24-24 24z" fill="" /><path d="M280 1011.2c-32.8 0-115.2-7.2-176.8-71.2-51.2-53.6-75.2-132-72-234.4 8-210.4 193.6-352 272.8-402.4l12.8-8-9.6-11.2c-20-22.4-53.6-66.4-61.6-117.6-4.8-31.2 2.4-59.2 20-80.8 21.6-28 47.2-33.6 64.8-33.6 19.2 0 40 7.2 60.8 20l8.8 6.4 7.2-9.6c18.4-24.8 52-55.2 103.2-56h1.6c52 0 85.6 30.4 104.8 56l7.2 9.6 9.6-6.4c20.8-13.6 41.6-20 60.8-20 17.6 0 43.2 5.6 64.8 33.6 17.6 22.4 24 50.4 19.2 80.8-5.6 37.6-28 80.8-61.6 117.6l-9.6 11.2 12.8 8c79.2 51.2 264.8 192 272.8 402.4 4 102.4-20.8 181.6-72 234.4-61.6 64-144.8 71.2-176.8 71.2H280z m85.6-688.8c-28.8 16-277.6 159.2-286.4 384.8-3.2 89.6 16.8 156.8 58.4 200 48.8 50.4 116 56 142.4 56h464c26.4 0 93.6-5.6 142.4-56 41.6-43.2 61.6-110.4 58.4-199.2-8-226.4-257.6-369.6-285.6-384.8l-4.8-3.2-5.6 1.6c-2.4 0.8-4.8 1.6-7.2 1.6H384c-2.4 0-4.8-0.8-8-1.6l-5.6-1.6-4.8 2.4z m-36-223.2c-6.4 0-16 1.6-27.2 15.2-9.6 12-12.8 26.4-9.6 44.8 6.4 46.4 48 95.2 72.8 115.2l4.8 4 6.4-1.6c3.2-0.8 4.8-1.6 6.4-1.6h257.6c0.8 0 2.4 0.8 4 0.8l8 2.4 4.8-4c24.8-20 66.4-68.8 72.8-115.2 2.4-18.4-0.8-32.8-9.6-44.8-10.4-13.6-20-15.2-27.2-15.2-23.2 0-51.2 24-60 33.6-4.8 4.8-11.2 8-17.6 8-1.6 0-3.2 0-4.8-0.8-8-1.6-15.2-7.2-17.6-15.2-2.4-7.2-27.2-64-81.6-64h-1.6c-56.8 0.8-80 61.6-80.8 64-3.2 8-9.6 13.6-17.6 15.2-1.6 0-3.2 0.8-4.8 0.8-6.4 0-12.8-2.4-17.6-8-8.8-9.6-36.8-33.6-60-33.6z" fill="" />
                  </svg>
                </span><p>Balances</p></a></li>
                <li className={`${activeLink === "/walletmanagement" ? "sub-active-link" : ""}`} onClick={() => handleClick("/walletmanagement")}><a><span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
                  </svg></span><p>Wallet Management</p></a></li>
                <li className={`${activeLink === "/wallettransaction" ? "sub-active-link" : ""}`} onClick={() => handleClick("/wallettransaction")}><a> <span>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2929 3.29289C15.6834 2.90237 16.3166 2.90237 16.7071 3.29289L22.3657 8.95147C23.1216 9.70743 22.5862 11 21.5172 11H2C1.44772 11 1 10.5523 1 10C1 9.44772 1.44772 9 2 9H19.5858L15.2929 4.70711C14.9024 4.31658 14.9024 3.68342 15.2929 3.29289ZM4.41421 15H22C22.5523 15 23 14.5523 23 14C23 13.4477 22.5523 13 22 13H2.48284C1.41376 13 0.878355 14.2926 1.63431 15.0485L7.29289 20.7071C7.68342 21.0976 8.31658 21.0976 8.70711 20.7071C9.09763 20.3166 9.09763 19.6834 8.70711 19.2929L4.41421 15Z" fill="currentColor" />
                  </svg></span><p>Transactions</p></a></li>
              </ul>
            )}
            </li>
          </ul>
          {/* <div className="bottom-menu">
            <a><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M5.85276 4.5835C3.83188 5.92715 2.5 8.22478 2.5 10.8335C2.5 14.9757 5.85787 18.3335 10 18.3335C14.1422 18.3335 17.5 14.9757 17.5 10.8335C17.5 8.22478 16.1681 5.92715 14.1472 4.5835" stroke="#6DC2FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 1.6665V8.33317" stroke="#6DC2FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </svg></span><p>Logout</p>
            </a>
            <a> <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M14.1667 9.83725C14.1667 9.54916 14.1667 9.40516 14.2101 9.27683C14.3361 8.90383 14.6682 8.75916 15.001 8.60758C15.3751 8.43716 15.5621 8.352 15.7474 8.337C15.9578 8.31998 16.1686 8.36533 16.3484 8.46625C16.5868 8.6 16.7531 8.85425 16.9233 9.061C17.7095 10.0158 18.1025 10.4933 18.2463 11.0198C18.3624 11.4447 18.3624 11.889 18.2463 12.3138C18.0366 13.0817 17.3738 13.7255 16.8832 14.3213C16.6323 14.6261 16.5068 14.7785 16.3484 14.8674C16.1686 14.9683 15.9578 15.0137 15.7474 14.9967C15.5621 14.9817 15.3751 14.8965 15.001 14.7261C14.6682 14.5745 14.3361 14.4298 14.2101 14.0568C14.1667 13.9285 14.1667 13.7845 14.1667 13.4964V9.83725Z" stroke="#6DC2FF" stroke-width="0.833333" />
              <path d="M5.83341 9.8373C5.83341 9.47464 5.82323 9.14864 5.53001 8.89364C5.42336 8.80089 5.28196 8.73647 4.99917 8.60764C4.62509 8.4373 4.43805 8.35214 4.25271 8.33714C3.69667 8.29214 3.39751 8.67164 3.07686 9.06114C2.29071 10.016 1.89763 10.4934 1.7538 11.0199C1.63773 11.4448 1.63773 11.8891 1.7538 12.314C1.96358 13.0819 2.62635 13.7256 3.11692 14.3215C3.42616 14.6971 3.72156 15.0397 4.25271 14.9968C4.43805 14.9818 4.62509 14.8966 4.99917 14.7262C5.28196 14.5974 5.42336 14.533 5.53001 14.4402C5.82323 14.1852 5.83341 13.8593 5.83341 13.4966V9.8373Z" stroke="#6DC2FF" stroke-width="0.833333" />
              <path d="M16.6666 8.74984V7.49984C16.6666 4.27818 13.6818 1.6665 9.99992 1.6665C6.31802 1.6665 3.33325 4.27818 3.33325 7.49984V8.74984" stroke="#6DC2FF" stroke-width="0.833333" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M16.6667 14.5835C16.6667 18.3335 13.3333 18.3335 10 18.3335" stroke="#6DC2FF" stroke-width="0.833333" stroke-linecap="round" stroke-linejoin="round" />
            </svg></span> <p>Help and Support</p></a>
          </div> */}
        </div>
        <div>

        </div>
      </div>

    </>
  )
}
