import axiosInstance from "./axiosInterceptor";

export const createWallet = async (payload, account) => {
  if (!payload) return;
  try {
    const { data } = await axiosInstance.post(`/add-wallet/${account}`, payload);
    if (data) return data;
  } catch (error) {
    throw error;
  }
};

export const listWallet = async (account) => {
  try {
    const { data } = await axiosInstance.get(`/list-wallet/${account}`);
    if (data) return data;
  } catch (error) {
    throw error;
  }
};

export const deleteWallet = async (account, walletAddress) => {
  try {
    const { data } = await axiosInstance.delete(`/delete-wallet/${account}?walletAddress=${walletAddress}`);
    if (data) return data;
  } catch (error) {
    throw error;
  }
};

export const listToken =async () => {
  try {
    const { data } = await axiosInstance.get(`/list-currency`);
    if (data) return data;
  } catch (error) {
    throw error;
  }
};
