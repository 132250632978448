import useScrollPosition from '@react-hook/window-scroll'
import { Trans } from '@lingui/macro'
import { SupportedChainId } from '../../constants/chains'
import { CardNoise } from '../earn/styled'
import { ExternalLink, TYPE } from '../../theme'
import Menu from '../Menu'
import Web3Status from '../Web3Status'
import ClaimModal from '../claim/ClaimModal'
import { Text } from 'rebass'
import { Dots } from '../swap/styleds'
import Modal from '../Modal'
import { useShowClaimPopup, useToggleSelfClaimModal } from 'state/application/hooks'
import UniBalanceContent from 'components/Header/UniBalanceContent'
import { useState } from 'react'
import { useActiveWeb3React } from 'hooks/web3'
import { useUserHasAvailableClaim } from 'state/claim/hooks'
import { useUserHasSubmittedClaim } from 'state/transactions/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import { useETHBalances } from 'state/wallet/hooks'
import styled from 'styled-components'
import { YellowCard } from 'components/Card'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import Row from 'components/Row'
import { useLocation } from 'react-router-dom';

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding:25px 50px;
  top: 0;
  z-index: 2;
  position: sticky;
  /* Background slide effect on scroll. */
  /* background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg3} 50% )}}`} */
  background-color: #0e1d30;
  background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
  transition: background-position .1s, box-shadow .1s;
   ${({ theme }) => theme.mediaWidth.upToLarge` 
      padding:25px 30px;
   `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns: auto 1fr;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1rem;
   
  `}
`
const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    // flex-direction: row;
    // justify-content: space-between;
    // justify-self: center;
    // width: 100%;
    // max-width: 960px;
    // padding: 1rem;
    // position: fixed;
    // bottom: 0px;
    // left: 0px;
    // width: 100%;
    // z-index: 99;
    // height: 72px;
    // border-radius: 12px 12px 0 0;
    // background-color: ${({ theme }) => theme.bg1};
  `};
`
const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  /* addresses safari's lack of support for "gap" */
  // & > *:not(:first-child) {
  //   margin-left: 8px;
  // }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    align-items: center;
    
  `};
`
const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`
const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg2)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  :focus {
    border: 1px solid blue;
  }
`
const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`
const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.9;
  }
`
const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`
const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`
const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`
const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`
const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  img {
    width: 7em;
  }
`
const activeClassName = 'ACTIVE'
const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;
  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
    background: ${({ theme }) => theme.bg7};
  }
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`
const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
}) <{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;
  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }
  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
    text-decoration: none;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: none;
`}
`
export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg2};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;
  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }
  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
    
`

/**
 * Store a given address in localStorage along with current date and time
 * 
 * @param {string} address - The address to store
 */
// export const storeAddress = (address: string) => {
//   // Retrieve existing addresses from localStorage
//   const storedData = JSON.parse(localStorage.getItem('walletAddresses') || '[]');
//   // Check if address is already stored
//   const addressExists = storedData.some((item: { address: string }) => item.address === address);
//   // If address does not exist, add it
//   if (!addressExists) {
//     // Get current date and time
//     const now = new Date();
//     const options: Intl.DateTimeFormatOptions = {
//       hour: 'numeric',
//       minute: 'numeric',
//       hour12: true,
//       day: 'numeric',
//       month: 'short',
//       year: 'numeric'
//     };
//     const formattedDateTime = now.toLocaleString('en-IN', options);
//     // Add address with current date and time
//     storedData.push({
//       address,
//       timestamp: formattedDateTime
//     });
//     // Save updated data to localStorage
//     localStorage.setItem('walletAddresses', JSON.stringify(storedData));
//   }
// };


/**
 * Header component used in pages. It includes links to various pages in the app,
 * as well as a claim button for UNI tokens and a Web3Status component to show
 * the user's account information.
 *
 * Also includes a dark mode toggle button.
 *
 * @param {object} props Component props
 * @param {boolean} props.isNeed Whether to show the menu links
 * @returns {React.ReactElement} The Header component
 */
export default function Header(props) {
  const { account, chainId } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()
  const [darkMode, toggleDarkMode] = useDarkModeManager()
  const toggleClaimModal = useToggleSelfClaimModal()
  const availableClaim: boolean = useUserHasAvailableClaim(account)
  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)
  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const showClaimPopup = useShowClaimPopup()
  const location = useLocation();
  let currentPath = location.pathname;
  const scrollY = useScrollPosition()
  const NETWORK_LABELS: { [chainId in SupportedChainId | number]: string } = {
    [SupportedChainId.MAINNET]: 'Mainnet',
    [SupportedChainId.RINKEBY]: 'Rinkeby',
    [SupportedChainId.ROPSTEN]: 'Ropsten',
    [SupportedChainId.GOERLI]: 'Görli',
    [SupportedChainId.KOVAN]: 'Kovan',
    [SupportedChainId.MUMBAI]: 'Mumbai',
    // [SupportedChainId.AMOY]: 'AMOY',
    [SupportedChainId.ARBITRUM_KOVAN]: 'kArbitrum',
    [SupportedChainId.ARBITRUM_ONE]: 'Arbitrum One',
  }
  const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${({ theme }) => theme.bg0};
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(50px);
  width: fit-content;
  padding: 4px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: flex-end;
  `};
`
  const headingMap = {
    '/newhome': 'DEX',
    '/balance': 'Balances',
    '/swap': 'DEX Swap',
    '/pool': 'DEX Pool',
    '/walletmanagement': 'Wallet Management',
    '/wallettransaction': 'Transactions'
    // Add more paths and headings as needed...
  };
  return (
    <>
      <HeaderFrame showBackground={scrollY > 45} >
        <ClaimModal />
        <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
          <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
        </Modal>
        <div className="menu-heading">
          <h3>{headingMap[currentPath] || ''}</h3> {/* Use headingMap here */}
        </div>
        { props.isNeed && <HeaderLinks>
          <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            <Trans>Swap</Trans>
          </StyledNavLink>
          <StyledNavLink
            id={`pool-nav-link`}
            to={'/pool'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/increase') ||
              pathname.startsWith('/find')
            }
          >
            <Trans>Pool</Trans>
          </StyledNavLink>
          </HeaderLinks>}
        <HeaderControls>
          <HeaderElement>
            <HideSmall>
              {chainId && chainId !== SupportedChainId.MAINNET && NETWORK_LABELS[chainId] && (
                <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
              )}
            </HideSmall>
            {availableClaim && !showClaimPopup && (
              <UNIWrapper onClick={toggleClaimModal}>
                <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                  <TYPE.white padding="0 2px">
                    {claimTxn && !claimTxn?.receipt ? (
                      <Dots>
                        <Trans>Claiming UNI</Trans>
                      </Dots>
                    ) : (
                      <Trans>Claim UNI</Trans>
                    )}
                  </TYPE.white>
                </UNIAmount>
                <CardNoise />
              </UNIWrapper>
            )}
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  <Trans>{userEthBalance?.toSignificant(4)} MATIC</Trans>
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            <Menu />
          </HeaderElementWrap>
        </HeaderControls>
      </HeaderFrame>
    </>
  )
}
