import React from 'react'
import styled from 'styled-components/macro'
import useCopyClipboard from '../../hooks/useCopyClipboard'

import { LinkStyledButton } from '../../theme'
import { CheckCircle, Copy } from 'react-feather'
import { Trans } from '@lingui/macro'

const CopyIcon = styled(LinkStyledButton)`
  color: ${({ theme }) => theme.text3};
  flex-shrink: 0;
  display: flex;
  text-decoration: none;
  font-size: 0.825rem;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => theme.text2};
  }
`
const TransactionStatusText = styled.span`
  margin-left: 0.25rem;
  font-size: 0.825rem;
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
`

/**
 * CopyHelper is a button for copying text to the clipboard.
 *
 * Props:
 *   toCopy: the string to copy
 *   children?: optional React node to render as the child of the button
 *
 * State:
 *   isCopied: whether the text has been copied
 *
 * @returns {ReactElement} a button with the text to copy and a checkmark
 *   icon if the text has been copied
 */
export default function CopyHelper(props: { toCopy: string; size: string; children?: React.ReactNode }) {
  const [isCopied, setCopied] = useCopyClipboard()
  return (
    <CopyIcon onClick={() => setCopied(props.toCopy)}>
      {isCopied ? (
        <TransactionStatusText>
          <CheckCircle size={props.size ||'16'} />
          <TransactionStatusText>
            <Trans>Copied</Trans>
          </TransactionStatusText>
        </TransactionStatusText>
      ) : (
        <TransactionStatusText>
          <Copy size={props.size ||'16'} />
        </TransactionStatusText>
      )}
      {isCopied ? '' : props.children}
    </CopyIcon>
  )
}
