import JSBI from 'jsbi'
import { Currency, CurrencyAmount, Fraction } from '@uniswap/sdk-core'

const CURRENCY_AMOUNT_MIN = new Fraction(JSBI.BigInt(1), JSBI.BigInt(1000000))

/**
 * FormattedCurrencyAmount
 *
 * Formats a currency amount in a human-readable format. If the amount is less than
 * 1/1000000, it will be displayed as "<0.000001".
 *
 * @param {CurrencyAmount} currencyAmount the amount to format
 * @param {number} significantDigits the number of significant digits to display
 * @return {string} the formatted currency amount
 */
export default function FormattedCurrencyAmount({
  currencyAmount,
  significantDigits = 4,
}: {
  currencyAmount: CurrencyAmount<Currency>
  significantDigits?: number
}) {
  return (
    <>
      {currencyAmount.equalTo(JSBI.BigInt(0))
        ? '0'
        : currencyAmount.greaterThan(CURRENCY_AMOUNT_MIN)
        ? currencyAmount.toSignificant(significantDigits)
        : `<${CURRENCY_AMOUNT_MIN.toSignificant(1)}`}
    </>
  )
}
