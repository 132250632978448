import "assets/index.css";
import TopBar from "../TopBar";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useActiveWeb3React } from "hooks/web3";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import axiosInstance from "service/axiosInterceptor";
import AddWallet from "./AddWallet";
import ListWallet from "./ListWallet";
import { listWallet } from "service/walletService";
import TransactionLoader from "components/Loader/TransactionLoader";
import ConfirmationModal from "components/shared/ConfirmationModal";


/**
 * @description The Header component renders the main layout for the wallet management
 * page. It renders the top bar, a table of wallet addresses, and a modal for adding
 * a new wallet address.
 *
 * @returns {React.ReactElement}
 */

interface Token {
  _id: string,
  tokenAddress: string,
  name: string
}

const Header: React.FC = () => {
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const { account, library } = useActiveWeb3React();
  const [errors, setErrors] = useState<Map<string, string>>(new Map());
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmed,setIsConfirmed] = useState(false)
  const [privateKey, setPrivateKey] = useState<string>("");
  const [selectedTokenType, setSelectedTokenType] = useState("");
  const [tokens, setTokens] = useState([])
  const [tokenBalance, setTokenBalance] = useState<string>("");
  const [decimal, setDecimal] = useState('')
  // const [label, setLabel] = useState('');
  const [toggleTransaction, setToggleTransaction] = useState(false)
  const [walletAddress, setWalletAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState<any>()

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Clean up the timeout on component unmount
  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleTransactionOpenModal = useCallback(
    () => setIsTransactionModalOpen(true),
    []
  );
  const handleTransactionCloseModal = useCallback(
    () => setIsTransactionModalOpen(false),
    []
  );
  const handleAddOpenModal = useCallback(() => setIsAddModalOpen(true), []);
  const handleAddCloseModal = useCallback(() => setIsAddModalOpen(false), []);

  // const getTokenAddress = (tokenType: string): string => {
  //   const addresses = {
  //     SNW: "0x38CA3c4b0377602559d6745fc1df40A9251E2670",
  //     FARM: "0xBF1544861f5AA2cc7b1994297BC3d7d957973A69",
  //     MATIC: "0x1bcd2a087f9024b644ed9710f777e24a6f647d59",
  //     LAXCE: "0xD415fe93a3F2E9f30513400C4EF68471dc207719"
  //   };
  //   return addresses[tokenType] || "";
  // };

  const tokenABI = [
    "function transfer(address to, uint256 value) public returns (bool)",
    "function balanceOf(address owner) public view returns (uint256)",
    "function decimals() public view returns (uint8)",
  ];

  // const tokenDecimals = {
  //   SNW: 18,
  //   FARM: 18,
  //   MATIC: 18,
  //   LAXCE: 18
  // };

  const labelRef = useRef<HTMLInputElement>(null);
  const amountRef = useRef<HTMLInputElement>(null);
  const [transactionAmount, setTransactionAmount] = useState("");

  const isValidWalletAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  // Validation function
  const validateField = (name, value) => {
    const newErrors = new Map(errors); // Copy existing errors

    if (name === "tokenType") {
      if (!value) {
        newErrors.set("tokenType", "Token is required");
      } else {
        newErrors.delete("tokenType"); // Remove error if valid
      }
    }

    if (name === "transactionAmount") {
      if (!value) {
        newErrors.set("transactionAmount", "Amount is required");
      } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
        newErrors.set("transactionAmount", "Amount must be a valid number");
      } else {
        newErrors.delete("transactionAmount"); // Remove error if valid
      }
    }

    setErrors(newErrors); // Update the errors state
  };

  const handleForm = () => {
    const newErrors = new Map();

    if (!selectedTokenType) {
      newErrors.set("tokenType", "Token is required");
    }

    if (!transactionAmount) {
      newErrors.set("transactionAmount", "Amount is required");
    } else if (!/^\d+(\.\d{1,2})?$/.test(transactionAmount)) {
      newErrors.set("transactionAmount", "Amount must be a valid number");
    }

    if (selectedAddress.address) {
      if (!isValidWalletAddress(selectedAddress.address)) {
        newErrors.set("toAddress", "Invalid wallet address");
      } else {
        newErrors.delete("toAddress"); // Remove error if valid
      }
    }
    
    setErrors(newErrors);

    // If there are no errors, proceed with the transaction
    if (newErrors.size === 0) {
      setToggleTransaction(true)
    }
  };

  const handleTransactionSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      setIsConfirmed(true)
      e.preventDefault();
      const amount = amountRef?.current?.value.trim();
      const tokenType = selectedTokenType

      if (!amount || !tokenType || !selectedAddress) return

      if (typeof window.ethereum === "undefined") {
        toast.error(
          "MetaMask is not installed. Please install it to proceed.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            theme: "dark",
          }
        );
        return;
      }

      try {
        setIsTransactionModalOpen(false)
        setToggleTransaction(false)
        setLoading(true);
        const provider = new ethers.providers.Web3Provider(
          window.ethereum as ethers.providers.ExternalProvider
        );
        const signer = provider.getSigner();
        let txResponse;

        if (tokenType === "MATIC") {
          const valueInWei = ethers.utils.parseEther(amount);
          txResponse = await signer.sendTransaction({
            to: selectedAddress.address,
            value: valueInWei,
          });
        } else {
          // const tokenAddress = getTokenAddress(tokenType);


          const tokenContract = new ethers.Contract(
            selectedTokenType,
            tokenABI,
            signer
          );
          const valueInWei = ethers.utils.parseUnits(
            amount,
            decimal
          );
          txResponse = await tokenContract.transfer(selectedAddress.address, valueInWei);
        }
        await txResponse.wait();
        toast.success(
          <div
            onClick={() =>
              window.open(
                "https://www.oklink.com/amoy/tx/" + txResponse.hash,
                "_blank"
              )
            }
            style={{ cursor: "pointer" }}
          >
            Transaction completed! Click here for more details.
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
            theme: "dark",
          }
        );
        setTransactionAmount('');
        setSelectedTokenType('')
      } catch (error) {

        console.log(error);
        let errorMessage = "An unknown error occurred";

        // Handle errors based on their code or message
        if (error?.code === ethers.errors.UNPREDICTABLE_GAS_LIMIT) {
          errorMessage = "Insufficient funds.";
        } else if (error?.data?.message) {
          errorMessage = error.data.message;
        } else if (error?.message) {
          errorMessage = error.message;
        }

        // Handle specific errors
        if (error.code === "INVALID_ARGUMENT") {
          console.error("Invalid argument provided:", error);
          errorMessage = "Invalid ENS Name Or Token Address Provided.";
        } else if (error.code === "ACTION_REJECTED" || errorMessage.includes("user rejected transaction")) {
          errorMessage = "It Looks Like You Rejected The Transaction.";
        } else if (errorMessage.includes("Internal JSON-RPC error")) {
          errorMessage = "Internal Server Error. Please Try Again Later.";
        }

        const insufficientFundsMessage = errorMessage.match(/insufficient funds/i)?.[0];
        if (insufficientFundsMessage) {
          errorMessage = `Transaction failed: ${insufficientFundsMessage}`;
        } else {
          errorMessage = `Transaction failed: ${errorMessage}`;
        }

        // Log the error for debugging purposes
        console.error("Transaction Error:", error);

        // Show the appropriate error message in the toast
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          theme: "dark",
        });
      } finally {
        setIsConfirmed(false)
        setLoading(false);
        // setToggleTransaction(false)
        handleTransactionCloseModal();
        setTransactionAmount('');
        setSelectedTokenType('');
        setTokenBalance('')
        if (amountRef.current) amountRef.current.value = '';
      }
    },
    [account, library, selectedTokenType, selectedAddress, decimal]
  );

  const handleConfirmButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent default action
    const form = document.querySelector(".form") as HTMLFormElement;
    if (form) {
      const syntheticEvent = {
        currentTarget: form,
        preventDefault: () => { },
      } as React.FormEvent<HTMLFormElement>;
      await handleTransactionSubmit(syntheticEvent);
    }
  };


  const fetchTokenBalance = useCallback(async (tokenType: string) => {
    if (!account) return; // Ensure account is available
    try {
      let balance: ethers.BigNumber;
      const provider = new ethers.providers.Web3Provider( // Initialize provider within the function
        window.ethereum as ethers.providers.ExternalProvider
      );
      if (tokenType === "MATIC") {
        balance = await provider.getBalance(account);
      } else {
        if (!selectedTokenType) return
        // const tokenAddress = getTokenAddress(tokenType);
        const tokenContract = new ethers.Contract(selectedTokenType, tokenABI, provider);
        balance = await tokenContract.balanceOf(account);
      }
      setTokenBalance(ethers.utils.formatUnits(balance, decimal));
    } catch (error) {
      console.log(error)
      console.error("Error fetching token balance:", error);
      setTokenBalance("Balance Unknown");
    }
  }, [account, selectedTokenType, decimal]);

  const fetchTokenDecimals = async (tokenAddress: string) => {
    try {
      // Initialize provider (make sure window.ethereum is available)
      const provider = new ethers.providers.Web3Provider(window.ethereum as ethers.providers.ExternalProvider);

      // Create a contract instance for the given token address
      const tokenContract = new ethers.Contract(tokenAddress, tokenABI, provider);

      // Call the decimals function from the contract
      const decimals = await tokenContract.decimals();

      // Return the decimals value
      return decimals;
    } catch (error) {
      console.error("Error fetching token decimals:", error);
      return null; // Handle error and return null or default value
    }
  };

  const getDecimals = async () => {
    console.log(selectedTokenType, 'selectedTokenType')
    const decimals = await fetchTokenDecimals(selectedTokenType);
    if (decimals) setDecimal(decimals)
    console.log("Token Decimals:", decimals);
  };


  const fetchToken = async () => {
    try {
      const { data } = await axiosInstance.get(`/list-currency`);
      if (data.data.length > 0) {
        setTokens(data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDecimals()
    fetchToken()
    fetchTokenBalance(selectedTokenType);
  }, [selectedTokenType, fetchTokenBalance]);

  const fetchWallet = async () => {
    setIsLoading(true)
    try {
      const { data } = await listWallet(account);
      if (data) {
        setWalletAddress(data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className={`content-section ${toggleTransaction ? "active" : ""}`}>
        <TopBar />
        <div className="menu-heading">
          <div className="balance_heading">
            <div>
              <button className="addnew" onClick={handleAddOpenModal}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.2361 5.29178C14.2361 4.77191 14.2361 4.51198 14.179 4.29871C14.0239 3.71997 13.5718 3.26793 12.9932 3.11285C12.4316 2.96238 11.5685 2.96238 11.0069 3.11285C10.4282 3.26793 9.97615 3.71997 9.82107 4.29871C9.76393 4.51198 9.76393 4.77191 9.76393 5.29178C9.76393 6.34588 9.76393 9.109 9.43647 9.43647C9.109 9.76393 6.34588 9.76393 5.29178 9.76393C4.77191 9.76393 4.51198 9.76393 4.29871 9.82107C3.71997 9.97615 3.26793 10.4282 3.11285 11.0069C2.96238 11.5685 2.96238 12.4315 3.11285 12.9931C3.26793 13.5718 3.71997 14.0239 4.29871 14.1789C4.51198 14.2361 4.77191 14.2361 5.29178 14.2361C6.34588 14.2361 9.109 14.2361 9.43647 14.5635C9.76393 14.891 9.76393 15.418 9.76393 16.4721C9.76393 16.992 9.76393 19.4881 9.82107 19.7013C9.97615 20.28 10.4282 20.7321 11.0069 20.8871C11.5685 21.0376 12.4316 21.0376 12.9932 20.8871C13.5718 20.7321 14.0239 20.28 14.179 19.7013C14.2361 19.4881 14.2361 16.992 14.2361 16.4721C14.2361 15.418 14.2361 14.891 14.5636 14.5635C14.891 14.2361 17.6541 14.2361 18.7083 14.2361C19.2281 14.2361 19.4881 14.2361 19.7014 14.1789C20.2801 14.0239 20.7321 13.5718 20.8872 12.9931C21.0377 12.4315 21.0377 11.5685 20.8872 11.0069C20.7321 10.4282 20.2801 9.97615 19.7014 9.82107C19.4881 9.76393 19.2281 9.76393 18.7083 9.76393C17.6541 9.76393 14.891 9.76393 14.5636 9.43647C14.2361 9.109 14.2361 6.34588 14.2361 5.29178Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Add New
              </button>
            </div>
          </div>
        </div>
        {isAddModalOpen && (
          <AddWallet handleAddCloseModal={handleAddCloseModal} fetchWallet={fetchWallet} />
        )}
        {isTransactionModalOpen && (
          <div
            className="header_wrap"
            style={{ position: "absolute", zIndex: 999 }}
            onClick={handleTransactionCloseModal}
          >
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <span
                className="close-button"
                onClick={handleTransactionCloseModal}
                style={{ cursor: "pointer" }}
              >
                &times;
              </span>
              <form className="form">
                <h2>Initiate Payment</h2>
                <div className="form-group">
                  <label htmlFor="fromAddress">From Address</label>
                  <input
                    type="text"
                    id="fromAddress"
                    name="fromAddress"
                    value={account || ""}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="label">Label</label>
                  <input
                    ref={labelRef}
                    type="text"
                    id="label"
                    name="toAddress"
                    placeholder="Search Label"
                    value={selectedAddress.label}
                    readOnly
                  />
                  {errors.get("label") && (
                    <span className="error">{errors.get("label")}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="toAddress">To Address</label>
                  <input
                    type="text"
                    id="toAddress"
                    name="toAddress"
                    placeholder="Enter address"
                    value={selectedAddress.address}
                    readOnly
                  />
                  {errors.get("toAddress") && (
                    <span className="error">{errors.get("toAddress")}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="tokenType">Select Token: {tokenBalance && `Balance - ${Number(tokenBalance).toFixed(4)}`}</label>
                  <select
                    id="tokenType"
                    name="tokenType"
                    value={selectedTokenType}
                    onChange={(e) => {
                      setSelectedTokenType(e.target.value);
                      validateField("tokenType", e.target.value); // Validate on change
                    }}
                  >
                    <option value="">Select a token</option>
                    {tokens.map((token: Token) => (
                      <option key={token._id} value={token.tokenAddress}>
                        {token.name}
                      </option>
                    ))}
                  </select>
                  {errors.get("tokenType") && (
                    <span className="error">{errors.get("tokenType")}</span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="transactionAmount">Amount</label>
                  <input
                    ref={amountRef}
                    type="text"
                    id="transactionAmount"
                    placeholder="Enter amount"
                    name="transactionAmount"
                    onChange={(e) => {
                      setTransactionAmount(e.target.value);
                      validateField("transactionAmount", e.target.value);
                    }}
                  />
                  {errors.get("transactionAmount") && (
                    <span className="error">
                      {errors.get("transactionAmount")}
                    </span>
                  )}
                </div>
                <div className="button-group">
                  <button type="button" className="submit-btn" onClick={handleForm}>
                    Make Transaction
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {loading && (<TransactionLoader />)}

       {toggleTransaction&& <ConfirmationModal
          show={toggleTransaction}
          prevent={isConfirmed}
          onClose={() => setToggleTransaction(false)}
          onConfirm={handleConfirmButtonClick} 
          title="Confirm"
          message="Are you sure you want to transfer the tokens?"
        />}

        <div className="container">
          <div className="wallet-management-detail">
            <div className="wallet-detail">
              <table className="table">
                <thead>
                  <tr>
                    <th>Label</th>
                    <th>Address</th>
                    <th>Date/Time</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <ListWallet setWalletAddress={setWalletAddress} isLoading={isLoading} walletAddress={walletAddress} handleTransactionOpenModal={handleTransactionOpenModal} fetchWallet={fetchWallet} setSelectedAddress={setSelectedAddress} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;