import { useCallback } from 'react'
import { useActiveWeb3React } from './web3'


declare global {
  interface Window {
    web3modal?: {
      clearCachedProvider: () => void
    }
  }
}

// Add this to your constants file or declare it here
const DISCONNECTED_KEY = 'WALLET_DISCONNECTED'

export const useDisconnectWallet = () => {
  const { deactivate, connector } = useActiveWeb3React()

  const disconnectWallet = useCallback(async () => {
    if (connector) {
      if ((connector as any).close) {
        await (connector as any).close()
      }
      
      if ((connector as any).deactivate) {
        await (connector as any).deactivate()
      }
    }

    await deactivate()
    
    // Set a flag in localStorage to indicate manual disconnect
    localStorage.setItem(DISCONNECTED_KEY, 'true')
    localStorage.removeItem('sessionToken')

    if (window.web3modal) {
      await window.web3modal.clearCachedProvider()
    }

  }, [deactivate, connector])

  return disconnectWallet
}
