import { RouteComponentProps } from "react-router-dom";
import TopBar from 'components/TopBar';
import Moneywallet from '../../assets/images/money-wallet.png'
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { useWalletModalToggle } from "state/application/hooks";

/**
 * Welcome is the first page that the user sees.
 * It contains a welcome message and a call-to-action
 * to get started.
 *
 * @param {RouteComponentProps} props The route component props.
 * @returns {JSX.Element} The welcome page.
 */
export default function Welcome({ history }: RouteComponentProps) {
    const { account, chainId } = useWeb3React();
    const toggleWalletModal = useWalletModalToggle()
  
    const handleGetStartedClick = () => {
      if (!account) {
        toggleWalletModal()
      } else {
        console.log('Already connected:', account)
        history.push("/balance")
      }
    }
  
    /**
     * Handles the login process.
     * If the user is not connected, it shows a toast
     * requesting the user to connect.
     * If the user is connected, it redirects to the swap page.
     */
    const handleLogin = () => {
        if(!account || !chainId) {
            toast.info(
                "Kindly connect to get started",
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                  theme: "dark",
                }
              );
              return
        }
        history.push("/swap")
    }
    
    return (
        <div className='content-section'>
            <TopBar isWelcome={true}/>
            <div className='container'>
                <div className='welcome-section'>
                    <div className='welcome-wrap'>
                        <h1>Welcome to <span>Archax Defi Products</span></h1>
                        <hr />
                        <p>Please select from the menu on the left</p>
                        <button type='button' className='get-btn' onClick={handleGetStartedClick}>Get Started</button>
                    </div>
                    <div className='welcome-image'>
                        <img src={ Moneywallet } alt='welcome' width={762} height={762}></img>
                    </div>
                </div>
            </div>
        </div>
      );
}