import { zodResolver } from "@hookform/resolvers/zod";
import { useActiveWeb3React } from "hooks/web3";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createWallet } from "service/walletService";
import { formatDate } from "utils/formatDate";
import { whitelistSchema } from "validations/whitelistSchema";
import { z } from "zod";

const AddWallet = ({ handleAddCloseModal, fetchWallet }) => {
    const { account } = useActiveWeb3React();
    const [loading, setLoading] = useState<boolean>(false);

    type WhitelistFormData = z.infer<typeof whitelistSchema>;

    const { register, handleSubmit, formState: { errors: formErrors }, reset } = useForm<WhitelistFormData>({
        resolver: zodResolver(whitelistSchema) as any,
    });

    // Handle form submission
    const handleWallet = async (data: WhitelistFormData) => {
        setLoading(true);
        try {
            const response = await createWallet(data, account);
            if (response.data) {
                fetchWallet();
                toast.success('Wallet successfully added', { autoClose: 3000 });
                reset();
                handleAddCloseModal();
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Failed to add wallet', { autoClose: 3000 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className="header_wrap"
            onClick={handleAddCloseModal}
            style={{ position: "absolute", zIndex: 100 }}
        >
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <form onSubmit={handleSubmit(handleWallet)}>
                    <span
                        className="close-button"
                        onClick={handleAddCloseModal}
                        style={{ cursor: "pointer" }}
                    >
                        &times;
                    </span>
                    <h2>Create User</h2>

                    <div className="form-group">
                        <label htmlFor="formlabel">Enter the label</label>
                        <input
                            type="text"
                            id="formlabel"
                            placeholder="Enter the label"
                            className={`input-field ${formErrors.label ? 'input-error' : ''}`}
                            {...register('label')}
                        />
                        {formErrors.label && <p className="error-text">{formErrors.label.message}</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="formwallet">Enter the wallet Address</label>
                        <input
                            type="text"
                            id="formwallet"
                            placeholder="Enter the wallet address"
                            className={`input-field ${formErrors.walletAddress ? 'input-error' : ''}`}
                            {...register('walletAddress')}
                        />
                        {formErrors.walletAddress && <p className="error-text">{formErrors.walletAddress.message}</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="createdAt">Date</label>
                        <input
                            type="text"
                            id="createdAt"
                            className={`input-field`}
                            value={formatDate(new Date())}
                        />
                    </div>


                    <div className="button-group">
                        <button type="submit" className="submit-btn" disabled={loading}>
                            {loading ? (
                                <i className="fa fa-circle-o-notch fa-spin"></i>
                            ) : (
                                'Add Wallet'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddWallet;
