import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import TopBar from 'components/TopBar';
import Moneywallet from '../../assets/images/money-wallet.png'
export default function ConnectWallet({ history }: RouteComponentProps) {
    return (
        <div className='content-section'>
            <TopBar/>
            <div className='container'>
                <div className='welcome-section'>
                    <div className='welcome-wrap'>
                        <h1>Please proceed with <span>Wallet Connect</span></h1>
                        <hr />
                        
                        <button type='button' className='get-btn' onClick={() => history.push("/swap")}>Connect wallet</button>
                    </div>
                    <div className='welcome-image'>
                        <img src={ Moneywallet } alt='welcome' width={762} height={762}></img>
                    </div>
                </div>
            </div>
        </div>
      );
}