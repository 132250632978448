import React from 'react'
import styled from 'styled-components/macro'
import useHttpLocations from '../../hooks/useHttpLocations'
import Logo from '../Logo'

const StyledListLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

/**
 * Renders a logo for a list.
 *
 * @param logoURI - The URI of the logo to use
 * @param style - CSS styles to apply to the logo
 * @param size - The size of the logo, in pixels. Defaults to 24.
 * @param alt - The alt text for the logo. Defaults to an empty string.
 */
export default function ListLogo({
  logoURI,
  style,
  size = '24px',
  alt,
}: {
  logoURI: string
  size?: string
  style?: React.CSSProperties
  alt?: string
}) {
  const srcs: string[] = useHttpLocations(logoURI)
  return <StyledListLogo alt={alt} size={size} srcs={srcs} style={style} />
}
