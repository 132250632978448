export const formatDateToIST = (dateString) => {
  return new Date(dateString).toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata",
  });
};

export const formatDate = (date) => {
  return new Date(date).toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata",
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,  // To show AM/PM
  });
};